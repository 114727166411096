/*
.generalContainerLanding{
    min-width: 100vw;
    min-height: 100vh;
    background-image: linear-gradient(to bottom right, #B59E8F, #AF8E5B);



    .sectionOne{
        min-height: 67vh;
        background-image: url('../../img/fondo.png');
        background-size: cover;
        background-size: 170%;
        

        background-position: center;
        
        background-position-x: -30vw;
        background-position-y: 0vh;
        background-repeat: no-repeat;


        .header{
            display: flex;
            .footersectionOne{
                min-width: 80vw;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                label{
                    min-width: 75vw;
                    max-width: 75vw;
                }
                .texone{
                    margin-top: 2vh;
                    font-size: 1.7rem;
                    color: #FFF;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 12vh;
                    font-family: Gabi;
                   
                }
                .textwo{
                    color: #fff;
                    font-size: 1.3rem;
                    min-height: 8vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: Avenir;
                    font-style: oblique;
                }
            }
            .footersectionTwo{
                min-width: 20vw;
                min-height: 10vh;
                max-height: 10vh;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    min-width: 10vw;
                }
            }
        }
        .pointsSelection{
            min-height: 42vh;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10vh;
            div{
                color: #FFF;
                border: 1px solid #FFF;
                min-width: 1.8rem;
                max-width: 1.8rem;
                min-height: 1.8rem;
                max-height: 1.8rem;
                border-radius: 1rem;
                font-size: 1.2rem;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                font-family: Avenir;
                opacity: 0.6;
            }
            .pointOne{
                margin-bottom: 18vh;
                margin-right: 12vw;
            }
            .pointTwo{
                margin-bottom: 5vh;
                margin-right: 50vw;
            }
            .pointThree{
                margin-bottom: 2vh;
                margin-right: 10vw;
            }
            .pointFour{
                margin-bottom: -16vh;
                margin-left: 52vw;
            }
            .pointFive{
                margin-bottom: -15vh;
                margin-right: 70vw;
            }
            .pointSix{
                margin-bottom: -15vh;
                margin-right: 0vw;
            }
            .pointSeven{
                margin-bottom: -22vh;
                margin-right: 42vw;
            }
        }
        .selected{
            background-color: #8E7144;
        }
        .optionselected{
            min-height: 8vh;
            background-color: rgba(255,255,255,0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1rem;
            letter-spacing: 0.2rem;
            color: #8E7144;
            font-weight: 600;
            font-family: Avenir;
        }
    }


    .sectionCard{
        margin-top: -2vh;
        min-height: 10vh;
        background-color: #8E7144;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        text-align: center;
        color: #FFF;
        font-family: Gabi;
        font-style: oblique;
    }


    .sectionForm{
        form{
            div{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                min-height: 10vh;
                font-family: Avenir;
                input,select{
                    color: #FFF;
                    min-width: 85vw;
                    border-radius: 0.5rem;
                    min-height: 4vh;
                    background-color: transparent;
                    border: none;
                    border: 1px solid rgba(255,255,255, 0.5);
                    color: #FFF;
                    font-size: 1.2rem;
                    text-align: center;
                    font-family: Avenir;
                    font-weight: 600;
                }
                select{
                    max-width: 20vw;
                    min-width: 20vw;
                }
                label{
                    font-size: 1rem;
                    color:#FFF;
                    letter-spacing: 0.2rem;
                    margin-top: 0.5rem;
                }
                input::placeholder,  select::placeholder{
                    color: #FFF;
                }
            }

            .agendacita{
                display: flex;
                min-height: 10vh;
                justify-content: center;
                flex-direction: row;
                align-items: center;
                font-size: 1.2rem;
                color: #FFF;
                label{
                    min-height: 6vh;
                    font-family: Avenir;
                    font-size: 1.3rem;

                }
                hr{
                    min-width: 15vw;
                }
            }
        }

    }
   
    .giftcard{
        min-width: 90vw;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 18vh;
        button{
            min-width: 90vw;
            min-height: 8vh;
            border-radius: 2vw;
            border-color: #FFF;
            background-color: #906D37;
            font-size: 1.5rem;
            color: #FFF;
            font-family: Avenir;
            font-weight: 600;
        }
    }

    .footer{
        min-width: 90vw;
        display: flex;
        flex-direction: column;
        div{
            display: flex;
            flex-direction: column;
            text-align: center;
            color: #FFF;
            justify-content: center;
            align-items: center;
            label{
                min-width: 95vw;
                max-width: 95vw;          
                font-size: .9rem;        
                font-family: Avenir;
                font-weight: 600;
                line-height: 1.5rem;
                a{
                    color: #FFF;
                }
            }
            img{
                margin-top: 5vh;
                margin-bottom: 5vh;
                max-width: 50vw;
            }
        }
    }
    
}

*/


/*
@media (min-width:1000px) {
    .generalContainerLanding{
        min-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to bottom right, #B59E8F, #AF8E5B);
    
        display: flex;
        flex-direction: row;
    
        .sectionOne{
            z-index: 1;
            position: absolute;
            min-height: 100vh;
            background-image: url('../../img/newfondo.png');
            background-size: cover;
            background-size: 110%;
                
            background-position: center;
            background-position-x: 0vw;
            background-repeat: no-repeat;
            max-width: 70vw;
            min-width: 70vw;
            .header{
                display: flex;
                max-width: 30vw;
                margin-left: 5vw;
                .footersectionOne{
                    min-width: 30vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    label{
                        min-width: 30vw;
                        max-width: 30vw;
                    }
                    .texone{
                        margin-top: 2vh;
                        font-size: 2rem;
                        color: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 12vh;
                        font-family: Gabi;
                    }
                    .textwo{
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        display: none;
                        min-width: 10vw;
                    }
                }
            }
            .pointsSelection{
                min-height: 60vh;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 0vh;
                div{
                    margin-left: 10vw;
                    color: #FFF;
                    border: 1px solid #FFF;
                    min-width: 2.5rem;
                    max-width: 2.5rem;
                    min-height: 2.5rem;
                    max-height: 2.5rem;
                    border-radius: 1.5rem;
                    font-size: 1.5rem;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    font-family: Avenir;
                    opacity: 0.6;
                }
                .pointOne{
                    margin-bottom: 25vh;
                    margin-right: 18vw;
                }
                .pointTwo{
                    margin-bottom: 5vh;
                    margin-right: 30vw;
                }
                .pointThree{
                    margin-bottom: -10vh;
                    margin-right: 18vw;
                }
                .pointFour{
                    margin-bottom: -60vh;
                    margin-left: 23vw;
                }
                .pointFive{
                    margin-bottom: -35vh;
                    margin-right: 50vw;
                }
                .pointSix{
                    margin-bottom: -55vh;
                    margin-right: 15vw;
                }
                .pointSeven{
                    margin-bottom: -70vh;
                    margin-right: 35vw;
                }
            }
            .selected{
                background-color: #8E7144;
            }
            .optionselected{
                min-width: 30vw;
                max-width: 30vw;
                margin-left: 15vw;
                min-height: 5vh;
                background-color: rgba(255,255,255,0.5);
                display: flex;
                margin-top: 10vh;
                justify-content: center;
                align-items: center;
                font-size: 1rem;
                letter-spacing: 0.2rem;
                color: #8E7144;
                font-weight: 600;
                font-family: Avenir;
            }
        }
    
    
        .sectionCard{
            z-index: 6;
            position: absolute;
            margin-top: 12vh;
            margin-left: 62.5vw;
            max-width: 35vw;
            min-height: 10vh;
            background-color: #8E7144;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.5rem;
            text-align: center;
            color: #FFF;
            font-family: Gabi;
            font-style: oblique;
        }
    
    
        
        .sectionForm{
            position: absolute;
            margin-left: 60vw;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 40vw;
            min-height: 100vh;
            backdrop-filter: blur(5px);
            background-color: rgba(164, 136,111, 0.6);
            form{
                z-index: 3;
                display: flex;
                flex-direction: column;
                div{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 10vh;
                    
                    font-family: Avenir;
                    input{
                        color: #FFF;
                        min-width: 20vw;
                        border-radius: 0.5rem;
                        min-height: 4vh;
                        background-color: transparent;
                        border: none;
                        border: 1px solid rgba(255,255,255, 0.5);
                        color: #FFF;
                        font-size: 0.9rem;
                        text-align: center;
                        font-family: Avenir;
                        font-weight: 600;
                    }
                    label{
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;
                        margin-top: 0.5rem;
                    }
                    input::placeholder{
                        color: #FFF;
                    }
                }
    
                .agendacita{
                    display: flex;
                    min-height: 10vh;
                    justify-content: center;
                    flex-direction: row;
                    align-items: center;
                    font-size: 1.2rem;
                    color: #FFF;
                    label{
                        min-height: 6vh;
                        font-family: Avenir;
                        font-size: 1.3rem;
    
                    }
                    hr{
                        min-width: 15vw;
                    }
                }
            }
    
        }
       
        .giftcard{
            z-index: 5;
            position: absolute;
            min-width: 20vw;
            max-width: 20vw;            
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 8vh;
            margin-top: 85vh;
            margin-left: 70vw;
            button{
                min-width: 20vw;
                min-height: 7vh;
                border-radius: 0.5rem;
                border-color: #FFF;
                background-color: #906D37;
                font-size: 1.2rem;
                color: #FFF;
                font-family: Avenir;
                font-weight: 600;
            }
        }
    
        .footer{
            z-index: 6;
            position: absolute;
            min-width: 60vw;
            display: flex;
            flex-direction: column;
            min-height: 5vh;
            margin-top: 90vh;
            div{
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                label{
                    min-width: 50vw;
                    max-width: 50vw;          
                    font-size: .9rem;        
                    font-family: Avenir;
                    font-weight: 600;
                    line-height: 1.5rem;
                    a{
                        color: #FFF;
                    }
                }
                img{
                    z-index: 5;
                    position: absolute;
                    display: flex;
                    margin-top: -180vh;
                    margin-left: 100vw;
                    margin-bottom: 0vh;                                        
                    min-width: 15vw;
                    max-width: 15vw;
                }
            }
        }
        
    }
}
*/

/*
@media (min-width:2300px) {
    .generalContainerLanding{
        min-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to bottom right, #B59E8F, #AF8E5B);
    
        display: flex;
        flex-direction: row;
    
        .sectionOne{
            z-index: 1;
            position: absolute;
            min-height: 100vh;
            background-image: url('../../img/newfondo.png');
            background-size: cover;
            background-size: 110%;
                
            background-position: center;
            background-position-x: 0vw;
            background-repeat: no-repeat;
            max-width: 70vw;
            min-width: 70vw;
            .header{
                display: flex;
                max-width: 30vw;
                margin-left: 5vw;
                .footersectionOne{
                    min-width: 30vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    label{
                        min-width: 30vw;
                        max-width: 30vw;
                    }
                    .texone{
                        margin-top: 2vh;
                        font-size: 4rem;
                        color: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 12vh;
                        font-family: Gabi;
                    }
                    .textwo{
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        display: none;
                        min-width: 10vw;
                    }
                }
            }
            .pointsSelection{
                min-height: 60vh;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 15vh;
                div{
                    margin-left: 10vw;
                    color: #FFF;
                    border: 1px solid #FFF;
                    min-width: 2.5rem;
                    max-width: 2.5rem;
                    min-height: 2.5rem;
                    max-height: 2.5rem;
                    border-radius: 1.5rem;
                    font-size: 1.5rem;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    font-family: Avenir;
                    opacity: 0.6;
                }
                .pointOne{
                    margin-bottom: 18vh;
                    margin-right: 12vw;
                }
                .pointTwo{
                    margin-bottom: 5vh;
                    margin-right: 20vw;
                }
                .pointThree{
                    margin-bottom: -5vh;
                    margin-right: 10vw;
                }
                .pointFour{
                    margin-bottom: -50vh;
                    margin-left: 28vw;
                }
                .pointFive{
                    margin-bottom: -25vh;
                    margin-right: 42vw;
                }
                .pointSix{
                    margin-bottom: -50vh;
                    margin-right: 10vw;
                }
                .pointSeven{
                    margin-bottom: -50vh;
                    margin-right: 28vw;
                }
            }
            .selected{
                background-color: #8E7144;
            }
            .optionselected{
                min-width: 30vw;
                max-width: 30vw;
                margin-left: 15vw;
                min-height: 5vh;
                background-color: rgba(255,255,255,0.5);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1rem;
                letter-spacing: 0.2rem;
                color: #8E7144;
                font-weight: 600;
                font-family: Avenir;
            }
        }
    
    
        .sectionCard{
            z-index: 6;
            position: absolute;
            margin-top: 12vh;
            margin-left: 70vw;
            max-width: 20vw;
            min-height: 10vh;
            background-color: #8E7144;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2rem;
            text-align: center;
            color: #FFF;
            font-family: Gabi;
            font-style: oblique;
        }
    
    
        
        .sectionForm{
            position: absolute;
            margin-left: 60vw;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 40vw;
            min-height: 100vh;
            backdrop-filter: blur(5px);
            background-color: rgba(164, 136,111, 0.6);
            form{
                z-index: 3;
                display: flex;
                flex-direction: column;
                div{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 10vh;
                    
                    font-family: Avenir;
                    input{
                        color: #FFF;
                        min-width: 20vw;
                        border-radius: 0.5rem;
                        min-height: 4vh;
                        background-color: transparent;
                        border: none;
                        border: 1px solid rgba(255,255,255, 0.5);
                        color: #FFF;
                        font-size: 1.2rem;
                        text-align: center;
                        font-family: Avenir;
                        font-weight: 600;
                    }
                    label{
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;
                        margin-top: 0.5rem;
                    }
                    input::placeholder{
                        color: #FFF;
                    }
                }
    
                .agendacita{
                    display: flex;
                    min-height: 10vh;
                    justify-content: center;
                    flex-direction: row;
                    align-items: center;
                    font-size: 1.2rem;
                    color: #FFF;
                    label{
                        min-height: 6vh;
                        font-family: Avenir;
                        font-size: 1.3rem;
    
                    }
                    hr{
                        min-width: 15vw;
                    }
                }
            }
    
        }
       
        .giftcard{
            z-index: 5;
            position: absolute;
            min-width: 20vw;
            max-width: 20vw;            
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 8vh;
            margin-top: 85vh;
            margin-left: 70vw;
            button{
                min-width: 20vw;
                min-height: 5vh;
                border-radius: 0.5rem;
                border-color: #FFF;
                background-color: #906D37;
                font-size: 1.5rem;
                color: #FFF;
                font-family: Avenir;
                font-weight: 600;
            }
        }
    
        .footer{
            position: absolute;
            min-width: 60vw;
            display: flex;
            flex-direction: column;
            min-height: 5vh;
            margin-top: 95vh;
            div{
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                label{
                    min-width: 50vw;
                    max-width: 50vw;          
                    font-size: .9rem;        
                    font-family: Avenir;
                    font-weight: 600;
                    line-height: 1.5rem;
                    a{
                        color: #FFF;
                    }
                }
                img{
                    z-index: 5;
                    position: absolute;
                    display: flex;
                    margin-top: -185vh;
                    margin-left: 100vw;
                    margin-bottom: 0vh;
                    
                    
                    min-width: 20vw;
                    max-width: 20vw;
                }
            }
        }
        
    }
}








*/











/* RESOLUCIONES  MOBILE */

@media (min-width:360px) and (min-height: 640px)  {
    .generalContainerLanding{
        min-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to bottom right, #B59E8F, #AF8E5B);
    
        .sectionOne{
            min-height: 67vh;
            background-image: url('../../img/fondo.png');
            background-size: cover;
            background-size: 170%;
            
    
            background-position: center;
            
            background-position-x: -30vw;
            background-position-y: 0vh;
            background-repeat: no-repeat;
    
    
            .header{
                padding-top: 5vh;
                display: flex;
                .footersectionOne{
                    min-width: 80vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    label{
                        min-width: 75vw;
                        max-width: 75vw;
                    }
                    .texone{
                        margin-top: 2vh;
                        font-size: 1.7rem;
                        color: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 12vh;
                        font-family: Gabi;
                       
                    }
                    .textwo{
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        min-width: 10vw;
                    }
                }
            }
            .pointsSelection{
                min-height: 42vh;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 12vh;
                div{
                    color: #FFF;
                    border: 1px solid rgba(255,255,255, 0.5);
                    min-width: 1.8rem;
                    max-width: 1.8rem;
                    min-height: 1.8rem;
                    max-height: 1.8rem;
                    border-radius: 1rem;
                    font-size: 1rem;
                    font-weight: 600;
                    line-height: 0rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    font-family: Avenir;
                    opacity: 0.6;
                    padding-top: 0.1rem;
                }
                .pointOne{
                    margin-bottom: 5vh;
                    margin-right: -55vw;
                }
                .pointTwo{
                    margin-bottom: 15vh;
                    margin-right: 40vw;
                }
                .pointThree{
                    margin-bottom: 15vh;
                    margin-right: 8vw;
                }
                .pointFour{
                    margin-bottom: -16vh;
                    margin-left: 52vw;
                }
                .pointFive{
                    margin-bottom: -15vh;
                    margin-right: 70vw;
                }
                .pointSix{
                    margin-bottom: -10vh;
                    margin-right: 0vw;
                }
                .pointSeven{
                    margin-bottom: -22vh;
                    margin-right: 42vw;
                }
                .pointEight{
                    margin-bottom: -0vh;
                    margin-right: 25vw;
                    z-index: 10;
                }
            }
            .selected{
                background-color: #8E7144;
            }
            .optionselected{
                min-height: 10vh;
                background-color: rgba(255,255,255,0.5);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1rem;
                letter-spacing: 0.2rem;
                color: #8E7144;
                font-weight: 600;
                margin-top: 0vh;
                margin-bottom: 1vh;
                font-family: Avenir;
            }
        }
    
    
        .sectionCard{
            margin-top: -2vh;
            min-height: 12vh;
            background-color: #8E7144;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.5rem;
            text-align: center;
            color: #FFF;
            font-family: Gabi;
            font-style: oblique;
        }
    
    
        .sectionForm{
            form{
                .conocenos{
                    text-align: center;
                    font-size: 1rem;
                    letter-spacing: 0rem;
                    font-family: Avenir;
                    font-weight: 600;
                    font-style: oblique;
                }
                .agendatucita{
                    text-align: center;
                    font-family: Avenir;
                    letter-spacing: 0rem;
                }
                .zonaElejidaLabel{
                    display: none;
                }
                .flechas{
                    min-height: 4vh;
                }
                div{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 10vh;
                    font-family: Avenir;
                    input,select{
                        color: #FFF;
                        min-width: 85vw;
                        border-radius: 0.5rem;
                        min-height: 7vh;
                        background-color: transparent;
                        border: none;
                        border: 1px solid rgba(255,255,255, 1);
                        color: #FFF;
                        font-size: 1.2rem;
                        text-align: center;
                        font-family: Avenir;
                        font-weight: 600;
                    }
                    select{
                        max-width: 20vw;
                        min-width: 20vw;
                    }
                    label{
                        font-size: 0.9rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;
                        margin-top: 0.5rem;
                        
                    }
                    input::placeholder,  select::placeholder{
                        color: rgba(255,255,255, 0.5);
                        font-size: 0.9rem;
                        letter-spacing: 0.1rem;
                    }
                    select{
                        margin-top: 0.5rem;
                        min-width: 87vw;
                        min-height: 7vh;
                        font-size: 0.9rem;
                        letter-spacing: 0.1rem;
                        color: rgba(255,255,255, 0.5);
                    }
                    .selectOptions{
                        min-width: 85vw;
                        display: flex;
                        flex-direction: column;
                        label{
                            min-width: 85vw;
                            display: flex;
                            .seleccionDesplegable{
                                min-width: 85vw;
                                max-width: 85vw;
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                align-items: flex-start;      
                                border: 1px solid #FFF;
                                border-radius: 0.5rem;
                            }
                            .imagen{
                                position: absolute;
                                min-width: 15vw;
                                max-height: 5vh;
                                
                                margin-left: 70vw;
                                min-height: 8vh;
                                border-top-right-radius: 0.5rem;
                                border-bottom-right-radius: 0.5rem;
                                border-left: none;
                                img{
                                    max-width: 12.5px;
                                    max-height: 12.5px;
                                }
                            }
                          
                        }
                        ul{
                            border: 1px solid #FFF;
                            display: flex;
                            flex-direction: column;
                            min-width: 85vw;
                            max-width: 85vw;
                            margin: 0;
                            padding: 0;
                            border-bottom-right-radius: 0.5rem;
                            border-bottom-left-radius: 0.5rem;
                            li{
                                min-height: 4vh;
                                list-style: none;
                                max-width: 85vw;
                                min-width: 65vw;
                                border-bottom: 1px solid rgba(255,255,255, 0.2);
                                margin-left: 2vw;
                            }
                        }
                    }

                    
                }
    
                .agendacita{
                    display: flex;
                    min-height: 10vh;
                    justify-content: center;
                    flex-direction: row;
                    align-items: center;
                    font-size: 1.2rem;
                    color: #FFF;
                    label{
                        min-height: 6vh;
                        font-family: Avenir;
                        font-size: 1.3rem;
    
                    }
                    hr{
                        min-width: 15vw;
                    }
                }
            }
    
        }
       
        .giftcard{
            min-width: 90vw;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 18vh;
            button{
                min-width: 88vw;
                min-height: 8vh;
                border-radius: 2vw;
                border-color: #FFF;
                background-color: #906D37;
                font-size: 1.2rem;
                letter-spacing: 0.1rem;
                color: #FFF;
                border: none;
                border: 1px solid #FFF;
                font-family: Avenir;
                font-weight: 600;
            }
        }
    
        .footer{
            min-width: 90vw;
            display: flex;
            flex-direction: column;
            div{
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                label{
                    min-width: 95vw;
                    max-width: 95vw;          
                    font-size: .9rem;        
                    font-family: Avenir;
                    font-weight: 400;
                    line-height: 1.5rem;
                    a{
                        color: #FFF;
                    }
                }
                img{
                    margin-top: 5vh;
                    margin-bottom: 5vh;
                    max-width: 50vw;
                }
            }
        }
        
    }
    

}

@media (min-width:360px) and (min-height: 780px)  {
    .generalContainerLanding{
        min-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to bottom right, #B59E8F, #AF8E5B);
    
        .sectionOne{
            min-height: 67vh;
            background-image: url('../../img/fondo.png');
            background-size: cover;
            background-size: 170%;
            
    
            background-position: center;
            
            background-position-x: -30vw;
            background-position-y: 0vh;
            background-repeat: no-repeat;
    
    
            .header{
                padding-top: 5vh;
                display: flex;
                .footersectionOne{
                    min-width: 80vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    label{
                        min-width: 75vw;
                        max-width: 75vw;
                    }
                    .texone{
                        margin-top: 2vh;
                        font-size: 1.7rem;
                        color: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 12vh;
                        font-family: Gabi;
                       
                    }
                    .textwo{
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        min-width: 10vw;
                    }
                }
            }
            .pointsSelection{
                min-height: 42vh;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 3vh;
                div{
                    color: #FFF;
                    border: 1px solid rgba(255,255,255, 0.5);
                    min-width: 1.8rem;
                    max-width: 1.8rem;
                    min-height: 1.8rem;
                    max-height: 1.8rem;
                    border-radius: 1rem;
                    font-size: 1rem;
                    font-weight: 600;
                    line-height: 0rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    font-family: Avenir;
                    opacity: 0.6;
                    padding-top: 0.1rem;
                }
                .pointOne{
                    margin-bottom: 5vh;
                    margin-right: -55vw;
                }
                .pointTwo{
                    margin-bottom: 5vh;
                    margin-right: 50vw;
                }
                .pointThree{
                    margin-bottom: 5vh;
                    margin-right: 10vw;
                }
                .pointFour{
                    margin-bottom: -16vh;
                    margin-left: 52vw;
                }
                .pointFive{
                    margin-bottom: -15vh;
                    margin-right: 70vw;
                }
                .pointSix{
                    margin-bottom: -15vh;
                    margin-right: 0vw;
                }
                .pointSeven{
                    margin-bottom: -22vh;
                    margin-right: 42vw;
                }
                .pointEight{
                    margin-bottom: -5vh;
                    margin-right: 25vw;
                    z-index: 10;
                }
            }
            .selected{
                background-color: #8E7144;
            }
            .optionselected{
                min-height: 10vh;
                background-color: rgba(255,255,255,0.5);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1rem;
                letter-spacing: 0.2rem;
                color: #8E7144;
                font-weight: 600;
                margin-top: 0vh;
                margin-bottom: 1vh;
                font-family: Avenir;
            }
        }
    
    
        .sectionCard{
            margin-top: -2vh;
            min-height: 12vh;
            background-color: #8E7144;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.5rem;
            text-align: center;
            color: #FFF;
            font-family: Gabi;
            font-style: oblique;
        }
    
    
        .sectionForm{
            form{
                .conocenos{
                    text-align: center;
                    font-size: 1rem;
                    letter-spacing: 0rem;
                    font-family: Avenir;
                    font-weight: 600;
                    font-style: oblique;
                }
                .agendatucita{
                    text-align: center;
                    font-family: Avenir;
                    letter-spacing: 0rem;
                }
                .zonaElejidaLabel{
                    display: none;
                }
                .flechas{
                    min-height: 4vh;
                }
                div{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 10vh;
                    font-family: Avenir;
                    input,select{
                        color: #FFF;
                        min-width: 85vw;
                        border-radius: 0.5rem;
                        min-height: 7vh;
                        background-color: transparent;
                        border: none;
                        border: 1px solid rgba(255,255,255, 1);
                        color: #FFF;
                        font-size: 1.2rem;
                        text-align: center;
                        font-family: Avenir;
                        font-weight: 600;
                    }
                    select{
                        max-width: 20vw;
                        min-width: 20vw;
                    }
                    label{
                        font-size: 0.9rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;
                        margin-top: 0.5rem;
                        
                    }
                    input::placeholder,  select::placeholder{
                        color: rgba(255,255,255, 0.5);
                        font-size: 0.9rem;
                        letter-spacing: 0.1rem;
                    }
                    select{
                        margin-top: 0.5rem;
                        min-width: 87vw;
                        min-height: 7vh;
                        font-size: 0.9rem;
                        letter-spacing: 0.1rem;
                        color: rgba(255,255,255, 0.5);
                    }
                    .selectOptions{
                        min-width: 85vw;
                        display: flex;
                        flex-direction: column;
                        label{
                            min-width: 85vw;
                            display: flex;
                            .seleccionDesplegable{
                                min-width: 85vw;
                                max-width: 85vw;
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                align-items: flex-start;      
                                border: 1px solid #FFF;
                                border-radius: 0.5rem;
                            }
                            .imagen{
                                position: absolute;
                                min-width: 15vw;
                                max-height: 5vh;
                                
                                margin-left: 70vw;
                                min-height: 8vh;
                                border-top-right-radius: 0.5rem;
                                border-bottom-right-radius: 0.5rem;
                                border-left: none;
                                img{
                                    max-width: 12.5px;
                                    max-height: 12.5px;
                                }
                            }
                          
                        }
                        ul{
                            border: 1px solid #FFF;
                            display: flex;
                            flex-direction: column;
                            min-width: 85vw;
                            max-width: 85vw;
                            margin: 0;
                            padding: 0;
                            border-bottom-right-radius: 0.5rem;
                            border-bottom-left-radius: 0.5rem;
                            li{
                                min-height: 4vh;
                                list-style: none;
                                max-width: 85vw;
                                min-width: 65vw;
                                border-bottom: 1px solid rgba(255,255,255, 0.2);
                                margin-left: 2vw;
                            }
                        }
                    }

                    
                }
    
                .agendacita{
                    display: flex;
                    min-height: 10vh;
                    justify-content: center;
                    flex-direction: row;
                    align-items: center;
                    font-size: 1.2rem;
                    color: #FFF;
                    label{
                        min-height: 6vh;
                        font-family: Avenir;
                        font-size: 1.3rem;
    
                    }
                    hr{
                        min-width: 15vw;
                    }
                }
            }
    
        }
       
        .giftcard{
            min-width: 90vw;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 18vh;
            button{
                min-width: 88vw;
                min-height: 8vh;
                border-radius: 2vw;
                border-color: #FFF;
                background-color: #906D37;
                font-size: 1.2rem;
                letter-spacing: 0.1rem;
                color: #FFF;
                border: none;
                border: 1px solid #FFF;
                font-family: Avenir;
                font-weight: 600;
            }
        }
    
        .footer{
            min-width: 90vw;
            display: flex;
            flex-direction: column;
            div{
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                label{
                    min-width: 95vw;
                    max-width: 95vw;          
                    font-size: .9rem;        
                    font-family: Avenir;
                    font-weight: 400;
                    line-height: 1.5rem;
                    a{
                        color: #FFF;
                    }
                }
                img{
                    margin-top: 5vh;
                    margin-bottom: 5vh;
                    max-width: 50vw;
                }
            }
        }
        
    }
    

}

@media (min-width:360px) and (min-height: 800px)  {

}

@media (min-width:390px) and (min-height: 844px)  {

}

@media (min-width:393px) and (min-height: 873px)  {

}

@media (min-width:412px) and (min-height: 915px)  {

}

@media (min-width:414px) and (min-height: 896px)  {

}



@media (min-width:428px) and (min-height: 926px)  {

}








/* RESPONSIVE IPAD */
@media (min-width:820px) and (min-height: 1073px)  and (orientation: portrait) {
    .generalContainerLanding{
        min-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to bottom right, #B59E8F, #AF8E5B);
    
        display: flex;
        flex-direction: row;

        .sectionOne{
            z-index: 5;
            position: absolute;
            min-height: 70vh;
            max-height: 70vh;
            background-image: url('../../img/fondo.png');
            background-size: cover;
            background-size: 100%;
                
            background-position: center;
            background-position-x: 0vw;
            background-position-y: -13vh;
            background-repeat: no-repeat;
            max-width: 100vw;
            min-width: 100vw;
            .header{
                z-index: 7;
                display: flex;
                max-width: 50vw;
                margin-left: 0vw;
                .footersectionOne{
                    min-width: 50vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-top: 8vh;
                    label{
                        min-width: 40vw;
                        max-width: 40vw;
                    }
                    .texone{
                        margin-top: -10vh;
                        font-size: 2.5rem;
                        color: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 12vh;
                        font-family: Gabi;
                        font-weight: lighter;

                    }
                    .textwo{
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{
                    //position: absolute;
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    display: none;
                    img{
                        display: none;
                        min-width: 10vw;
                    }
                }
            }
            .pointsSelection{
                min-height: 30vh;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 0vh;
                div{    
                    margin-left: 10vw;
                    color: #FFF;
                    border: 1px solid #FFF;
                    border: 1px solid rgba(255,255,255, 0.5);
                    min-width: 2.5rem;
                    max-width: 2.5rem;
                    min-height: 2.5rem;
                    max-height: 2.5rem;
                    border-radius: 1.5rem;
                    font-size: 1.5rem;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    font-family: Avenir;
                    opacity: 0.6;
                }
                .pointOne{
                    margin-bottom: 15vh;
                    margin-right: -20vw;
                }
                .pointTwo{
                    margin-bottom: 15vh;
                    margin-right: 45vw;
                }
                .pointThree{
                    margin-bottom: 15vh;
                    margin-right: 25vw;
                }
                .pointFour{
                    margin-bottom: -12vh;
                    margin-left: 25vw;
                }
                .pointFive{
                    margin-bottom: 0vh;
                    margin-right: 60vw;
                }
                .pointSix{
                    margin-bottom: -8vh;
                    margin-right: 15vw;
                }
                .pointSeven{
                    margin-bottom: -20vh;
                    margin-right: 40vw;
                }
                .pointEight{
                    margin-bottom: -0vh;
                    margin-right: 28vw;
                }
            }
            .selected{
                background-color: #8E7144;
                
            }
            .optionselected{
                min-width: 100vw;
                max-width: 100vw;
                margin-left: 0vw;
                min-height: 5vh;
                background-color: rgba(255,255,255,0.5);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1rem;
                letter-spacing: 0.2rem;
                color: #8E7144;
                font-weight: 600;
                font-family: Avenir;
            }
        }
    
    
        .sectionCard{
            z-index: 10;
            position: absolute;
            margin-top: 50vh;
            margin-left: 0vw;
            min-width: 100vw;
            max-width: 100vw;
            min-height: 5vh;
            background-color: #8E7144;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.5rem;
            text-align: center;
            color: #FFF;
            font-family: Gabi;
            font-style: oblique;

        }
    
    
        
        .sectionForm{
            position: absolute;
            margin-left: 0vw;
            z-index: 6;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-top: 0vh;
            align-items: center;
            min-width: 100vw;
            min-height: 45vh;
            padding-top: 0vh;   
            padding-bottom: 0vh;            
            backdrop-filter: blur(5px);
            background-color: rgba(181,158,143, 0.2);
            margin-top: 55vh;
            form{
                z-index: 8;
                min-width: 80vw;
                display: flex;
                flex-direction: column;
                margin: 0;
                div{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 4vh;
                    margin: 0;
                    font-family: Avenir;

                    .selectOptions{
                        z-index: 10;
                        min-width: 80vw;
                        max-width: 80vw;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        label{
                            min-width: 80vw;
                            max-width: 80vw;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .seleccionDesplegable{
                            min-width: 80vw ;
                            max-width: 80vw ;                    
                            }
                            .imagen{
                                z-index: 20;
                                margin-left: 0;
                                min-height: 5vh;
                                margin-left: 25vw;
                                min-width: 5vw;
                                min-width: 5vw;

                            }
                           
                        }   
                        ul{
                            z-index: 60;
                            background-color: rgba(255,255,255, 0.2);
                            min-width: 80vw;
                            max-width: 80vw;
                            margin: 0;
                            color: #000;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            
                            li{
                                z-index: 60;
                                color: #000;
                                z-index: 20;
                                margin: 0;
                                min-width: 20vw;
                                max-width: 20vw;
                                text-align: left;
                                label{
                                    font-size: 0.8rem;
                                    text-align: center;
                                    min-width: 25vw;
                                    max-width: 20vw;
                                }
                            }
                        
                        }                    
                    }
    
                    input{
                        z-index: 8;
                        color: #FFF;
                        min-width: 80vw;
                        border-radius: 0.5rem;
                        min-height: 3vh;
                        background-color: transparent;
                        border: none;
                        border: 1px solid rgba(255,255,255, 0.5);
                        color: #FFF;
                        font-size: 1rem;
                        text-align: center;
                        font-family: Avenir;
                        font-weight: 600;
                        margin: 0;
                    }
                    label{
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;
                        margin-top: 0.5rem;
                        
                    }
                    input::placeholder{
                        color: #FFF;
                    }
                  
                }
                .flechas{
                    display: none;
                }
                .agendacita{
                    z-index: 6;
                    display: flex;
                    min-height: 4vh;
                    justify-content: center;
                    flex-direction: row;
                    align-items: center;
                    font-size: 1.2rem;
                    color: #FFF;
                    label{
                        min-height: 4vh;
                        font-family: Avenir;
                        font-size: 1.3rem;
    
                    }
                    hr{
                        min-width: 15vw;
                    }
                }
            }
    
        }
       
        .giftcard{
            z-index: 8;
            position: absolute;
            min-width: 80vw;
            max-width: 80vw;            
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 4vh;
            margin-top: 90vh;
            margin-left: 10vw;
            button{
                min-width: 80vw;
                min-height: 4vh;
                border-radius: 0.5rem;
                border-color: #FFF;
                background-color: #906D37;
                font-size: 1rem;
                color: #FFF;
                font-family: Avenir;
                font-weight: 600;
            }
        }
    
        .footer{
            z-index: 8;
            position: absolute;
            min-width: 100vw;
            display: flex;
            flex-direction: column;
            min-height: 2vh;
            margin-top: 95vh;
           //display: none;
            div{
                display: flex;
                flex-direction: row;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                
                label{
                    min-width: 50vw;
                    max-width: 50vw;          
                    font-size: .9rem;        
                    font-family: Avenir;
                    font-weight: 600;
                    line-height: 1.5rem;
                    a{
                        color: #FFF;
                        text-align: left;
                    }
                }
                img{
                    z-index: 8;
                    position: absolute;
                    display: flex;
                    margin-top: -180vh;
                    margin-left: 50vw;
                    margin-bottom: 0vh;
                    
                    
                    min-width: 30vw;
                    max-width: 30vw;
                }
                .visita{
                    min-width: 35vw;
                    max-width: 35vw;
                    text-align: right;   
                }
            }
        }
        
    }
}



@media (min-width:1180px) and (min-height: 713px)  and (orientation: landscape) {
    .generalContainerLanding{
        min-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to bottom right, #B59E8F, #AF8E5B);
    
        display: flex;
        flex-direction: row;
    
        .sectionOne{

            z-index: 5;
            position: absolute;
            min-height: 100vh;
            max-height: 100vh;
            background-image: url('../../img/newfondo.png');
            background-size: cover;
            background-size: 120%;
                
            background-position: center;
            background-position-x: -5vw;
            background-repeat: no-repeat;
            max-width: 70vw;
            min-width: 70vw;
            .header{
                z-index: 7;
                display: flex;
                max-width: 50vw;
                margin-left: 0vw;
                .footersectionOne{
                    min-width: 50vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-top: 8vh;
                    label{
                        min-width: 40vw;
                        max-width: 40vw;
                    }
                    .texone{
                        margin-top: -5vh;
                        font-size: 2.5rem;
                        color: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 12vh;
                        font-family: Gabi;
                        font-weight: lighter;

                    }
                    .textwo{
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    display: none;
                    img{
                        display: none;
                        min-width: 10vw;
                    }
                }
            }
            .pointsSelection{
                min-height: 55vh;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 5vh;
              
                div{
                    margin-left: 10vw;
                    color: #FFF;
                    border: 1px solid #FFF;
                    border: 1px solid rgba(255,255,255, 0.5);
                    min-width: 2.5rem;
                    max-width: 2.5rem;
                    min-height: 2.5rem;
                    max-height: 2.5rem;
                    border-radius: 1.5rem;
                    font-size: 1.5rem;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    font-family: Avenir;
                    opacity: 0.6;
                }
                .pointOne{
                    margin-bottom: 15vh;
                    margin-right: -10vw;
                }
                .pointTwo{
                    margin-bottom: 15vh;
                    margin-right: 35vw;
                }
                .pointThree{
                    margin-bottom: 15vh;
                    margin-right: 22vw;
                }
                .pointFour{
                    margin-bottom: -30vh;
                    margin-left: 20vw;
                }
                .pointFive{
                    margin-bottom: -25vh;
                    margin-right: 55vw;
                }
                .pointSix{
                    margin-bottom: -25vh;
                    margin-right: 17vw;
                }
                .pointSeven{
                    margin-bottom: -40vh;
                    margin-right: 42vw;
                }
                .pointEight{
                    margin-bottom: -5vh;
                    margin-right: 28vw;
                }
            }
            .selected{
                background-color: #8E7144;
            }
            .optionselected{
                min-width: 30vw;
                max-width: 30vw;
                margin-left: 15vw;
                min-height: 5vh;
                background-color: rgba(255,255,255,0.5);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1rem;
                letter-spacing: 0.2rem;
                color: #8E7144;
                font-weight: 600;
                font-family: Avenir;
            }
        }
    
    
        .sectionCard{
            z-index: 10;
            position: absolute;
            margin-top: 15vh;
            margin-left: 62.5vw;
            max-width: 35vw;
            max-width: 35vw;
            min-height: 14vh;
            background-color: #8E7144;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2rem;
            text-align: center;
            color: #FFF;
            font-family: Gabi;
            font-style: oblique;
        }
    
    
        
        .sectionForm{
            position: absolute;
            margin-left: 60vw;
            z-index: 6;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 0vh;
            align-items: center;
            min-width: 40vw;
            min-height: 70vh;
            padding-top: 20vh;   
            padding-bottom: 10vh;            
            backdrop-filter: blur(5px);
            background-color: rgba(181,158,143, 0.2);
            form{
                z-index: 8;
                display: flex;
                flex-direction: column;
                margin: 0;
                div{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 5vh;
                    margin: 0;
                    font-family: Avenir;
                    .selectOptions{
                        z-index: 10;
                        min-width: 30vw;
                        max-width: 30vw;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        label{
                            min-width: 30vw;
                            max-width: 30vw;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .seleccionDesplegable{
                            min-width: 30vw;
                            max-width: 30vw;                    
                        }
                            .imagen{
                                z-index: 20;
                                margin-left: 0;
                                min-height: 5vh;
                                margin-left: 25vw;
                                min-width: 5vw;
                                min-width: 5vw;

                            }
                           
                        }   
                        ul{
                            z-index: 20;
                            z-index: 60;
                            background-color: rgba(255,255,255, 0.2);
                            min-width: 30vw;
                            max-width: 30vw;
                            margin: 0;
                            color: #000;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            
                            li{
                                color: #000;
                                z-index: 20;
                                margin: 0;
                                min-width: 15vw;
                                max-width: 15vw;
                                text-align: left;
                                label{
                                    text-align: center;
                                    min-width: 15vw;
                                    max-width: 15vw;
                                }
                            }
                        
                        }                    
                    }
    
                    input{
                        z-index: 8;
                        color: #FFF;
                        min-width: 30vw;
                        border-radius: 0.5rem;
                        min-height: 4vh;
                        background-color: transparent;
                        border: none;
                        border: 1px solid rgba(255,255,255, 0.5);
                        color: #FFF;
                        font-size: 1rem;
                        text-align: center;
                        font-family: Avenir;
                        font-weight: 600;
                        margin: 0;
                    }
                    label{
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;
                        margin-top: 0.5rem;
                        
                    }
                    input::placeholder{
                        color: #FFF;
                    }
                  
                }
    
                .agendacita{
                    z-index: 6;
                    display: flex;
                    min-height: 10vh;
                    justify-content: center;
                    flex-direction: row;
                    align-items: center;
                    font-size: 1.2rem;
                    color: #FFF;
                    label{
                        min-height: 6vh;
                        font-family: Avenir;
                        font-size: 1.3rem;
    
                    }
                    hr{
                        min-width: 15vw;
                    }
                }
            }
    
        }
       
        .giftcard{
            z-index: 8;
            position: absolute;
            min-width: 30vw;
            max-width: 30vw;            
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 10vh;
            margin-top: 85vh;
            margin-left: 65vw;
            button{
                min-width: 30vw;
                min-height: 8vh;
                border-radius: 0.5rem;
                border-color: #FFF;
                background-color: #906D37;
                font-size: 1rem;
                color: #FFF;
                font-family: Avenir;
                font-weight: 600;
            }
        }
    
        .footer{
            z-index: 8;
            position: absolute;
            min-width: 60vw;
            display: flex;
            flex-direction: column;
            min-height: 5vh;
            margin-top: 90vh;
            div{
                display: flex;
                flex-direction: row;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                label{
                    min-width: 20vw;
                    max-width: 30vw;          
                    font-size: .9rem;        
                    font-family: Avenir;
                    font-weight: 600;
                    line-height: 1.5rem;
                    a{
                        color: #FFF;
                    }
                }
                img{
                    z-index: 8;
                    position: absolute;
                    display: flex;
                    margin-top: -170vh;
                    margin-left: 100vw;
                    margin-bottom: 0vh;
                    
                    
                    min-width: 20vw;
                    max-width: 20vw;
                }
            }
        }
        
    }
   
}


/* FIN DE RESPONSIVE */







/* RESOLUCIONES  DESKTOP */
@media (min-width:1280px) and (min-height: 720px)  {
    .generalContainerLanding{
        min-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to bottom right, #B59E8F, #AF8E5B);
    
        display: flex;
        flex-direction: row;
    
        .sectionOne{

            z-index: 5;
            position: absolute;
            min-height: 100vh;
            max-height: 100vh;
            background-image: url('../../img/newfondo.png');
            background-size: cover;
            background-size: 120%;
                
            background-position: center;
            background-position-x: -5vw;
            background-repeat: no-repeat;
            max-width: 70vw;
            min-width: 70vw;
            .header{
                z-index: 7;
                display: flex;
                max-width: 50vw;
                margin-left: 0vw;
                .footersectionOne{
                    min-width: 50vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-top: 8vh;
                    label{
                        min-width: 40vw;
                        max-width: 40vw;
                    }
                    .texone{
                        margin-top: -5vh;
                        font-size: 2.5rem;
                        color: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 12vh;
                        font-family: Gabi;
                        font-weight: lighter;

                    }
                    .textwo{
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    display: none;
                    img{
                        display: none;
                        min-width: 10vw;
                    }
                }
            }
            .pointsSelection{
                min-height: 55vh;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 5vh;
              
                div{
                    margin-left: 10vw;
                    color: #FFF;
                    border: 1px solid #FFF;
                    border: 1px solid rgba(255,255,255, 0.5);
                    min-width: 2.5rem;
                    max-width: 2.5rem;
                    min-height: 2.5rem;
                    max-height: 2.5rem;
                    border-radius: 1.5rem;
                    font-size: 1.5rem;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    font-family: Avenir;
                    opacity: 0.6;
                }
                .pointOne{
                    margin-bottom: 15vh;
                    margin-right: -10vw;
                }
                .pointTwo{
                    margin-bottom: 15vh;
                    margin-right: 35vw;
                }
                .pointThree{
                    margin-bottom: 15vh;
                    margin-right: 22vw;
                }
                .pointFour{
                    margin-bottom: -30vh;
                    margin-left: 20vw;
                }
                .pointFive{
                    margin-bottom: -25vh;
                    margin-right: 55vw;
                }
                .pointSix{
                    margin-bottom: -25vh;
                    margin-right: 17vw;
                }
                .pointSeven{
                    margin-bottom: -40vh;
                    margin-right: 42vw;
                }
                .pointEight{
                    margin-bottom: -5vh;
                    margin-right: 28vw;
                }
            }
            .selected{
                background-color: #8E7144;
            }
            .optionselected{
                min-width: 30vw;
                max-width: 30vw;
                margin-left: 15vw;
                min-height: 5vh;
                background-color: rgba(255,255,255,0.5);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1rem;
                letter-spacing: 0.2rem;
                color: #8E7144;
                font-weight: 600;
                font-family: Avenir;
            }
        }
    
    
        .sectionCard{
            z-index: 10;
            position: absolute;
            margin-top: 15vh;
            margin-left: 62.5vw;
            max-width: 35vw;
            max-width: 35vw;
            min-height: 14vh;
            background-color: #8E7144;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2rem;
            text-align: center;
            color: #FFF;
            font-family: Gabi;
            font-style: oblique;
        }
    
    
        
        .sectionForm{
            position: absolute;
            margin-left: 60vw;
            z-index: 6;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 0vh;
            align-items: center;
            min-width: 40vw;
            min-height: 70vh;
            padding-top: 20vh;   
            padding-bottom: 10vh;            
            backdrop-filter: blur(5px);
            background-color: rgba(181,158,143, 0.2);
            form{
                z-index: 8;
                display: flex;
                flex-direction: column;
                margin: 0;
                div{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 5vh;
                    margin: 0;
                    font-family: Avenir;
                    .selectOptions{
                        z-index: 10;
                        min-width: 30vw;
                        max-width: 30vw;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        label{
                            min-width: 30vw;
                            max-width: 30vw;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .seleccionDesplegable{
                            min-width: 30vw;
                            max-width: 30vw;                    
                        }
                            .imagen{
                                z-index: 20;
                                margin-left: 0;
                                min-height: 5vh;
                                margin-left: 25vw;
                                min-width: 5vw;
                                min-width: 5vw;

                            }
                           
                        }   
                        ul{
                            z-index: 20;
                            z-index: 60;
                            background-color: rgba(255,255,255, 0.2);
                            min-width: 30vw;
                            max-width: 30vw;
                            margin: 0;
                            color: #000;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            
                            li{
                                color: #000;
                                z-index: 20;
                                margin: 0;
                                min-width: 15vw;
                                max-width: 15vw;
                                text-align: left;
                                label{
                                    text-align: center;
                                    min-width: 15vw;
                                    max-width: 15vw;
                                }
                            }
                        
                        }                    
                    }
    
                    input{
                        z-index: 8;
                        color: #FFF;
                        min-width: 30vw;
                        border-radius: 0.5rem;
                        min-height: 4vh;
                        background-color: transparent;
                        border: none;
                        border: 1px solid rgba(255,255,255, 0.5);
                        color: #FFF;
                        font-size: 1rem;
                        text-align: center;
                        font-family: Avenir;
                        font-weight: 600;
                        margin: 0;
                    }
                    label{
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;
                        margin-top: 0.5rem;
                        
                    }
                    input::placeholder{
                        color: #FFF;
                    }
                  
                }
    
                .agendacita{
                    z-index: 6;
                    display: flex;
                    min-height: 10vh;
                    justify-content: center;
                    flex-direction: row;
                    align-items: center;
                    font-size: 1.2rem;
                    color: #FFF;
                    label{
                        min-height: 6vh;
                        font-family: Avenir;
                        font-size: 1.3rem;
    
                    }
                    hr{
                        min-width: 15vw;
                    }
                }
            }
    
        }
       
        .giftcard{
            z-index: 8;
            position: absolute;
            min-width: 30vw;
            max-width: 30vw;            
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 10vh;
            margin-top: 85vh;
            margin-left: 65vw;
            button{
                min-width: 30vw;
                min-height: 8vh;
                border-radius: 0.5rem;
                border-color: #FFF;
                background-color: #906D37;
                font-size: 1rem;
                color: #FFF;
                font-family: Avenir;
                font-weight: 600;
            }
        }
    
        .footer{
            z-index: 8;
            position: absolute;
            min-width: 60vw;
            display: flex;
            flex-direction: column;
            min-height: 5vh;
            margin-top: 90vh;
            div{
                display: flex;
                flex-direction: row;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                label{
                    min-width: 20vw;
                    max-width: 30vw;          
                    font-size: .9rem;        
                    font-family: Avenir;
                    font-weight: 600;
                    line-height: 1.5rem;
                    a{
                        color: #FFF;
                    }
                }
                img{
                    z-index: 8;
                    position: absolute;
                    display: flex;
                    margin-top: -170vh;
                    margin-left: 100vw;
                    margin-bottom: 0vh;
                    
                    
                    min-width: 20vw;
                    max-width: 20vw;
                }
            }
        }
        
    }
}

@media (min-width:1366px) and (min-height: 768px)  {
    .generalContainerLanding{
        min-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to bottom right, #B59E8F, #AF8E5B);
    
        display: flex;
        flex-direction: row;
    
        .sectionOne{

            z-index: 5;
            position: absolute;
            min-height: 100vh;
            max-height: 100vh;
            background-image: url('../../img/newfondo.png');
            background-size: cover;
            background-size: 120%;
                
            background-position: center;
            background-position-x: -5vw;
            background-repeat: no-repeat;
            max-width: 70vw;
            min-width: 70vw;
            .header{
                z-index: 7;
                display: flex;
                max-width: 50vw;
                margin-left: 0vw;
                .footersectionOne{
                    min-width: 50vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-top: 5vh;
                    label{
                        min-width: 35vw;
                        max-width: 35vw;
                    }
                    .texone{
                        margin-top: -5vh;
                        font-size: 2.5rem;
                        color: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 12vh;
                        font-family: Gabi;
                        font-weight: lighter;

                    }
                    .textwo{
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    display: none;
                    img{
                        display: none;
                        min-width: 10vw;
                    }
                }
            }
            .pointsSelection{
                min-height: 55vh;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 10vh;
              
                div{
                    margin-left: 10vw;
                    color: #FFF;
                    border: 1px solid #FFF;
                    border: 1px solid rgba(255,255,255, 0.5);
                    min-width: 2.5rem;
                    max-width: 2.5rem;
                    min-height: 2.5rem;
                    max-height: 2.5rem;
                    border-radius: 1.5rem;
                    font-size: 1.5rem;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    font-family: Avenir;
                    opacity: 0.6;
                }
                .pointOne{
                    margin-bottom: 15vh;
                    margin-right: -10vw;
                }
                .pointTwo{
                    margin-bottom: 15vh;
                    margin-right: 35vw;
                }
                .pointThree{
                    margin-bottom: 15vh;
                    margin-right: 22vw;
                }
                .pointFour{
                    margin-bottom: -30vh;
                    margin-left: 20vw;
                }
                .pointFive{
                    margin-bottom: -25vh;
                    margin-right: 55vw;
                }
                .pointSix{
                    margin-bottom: -25vh;
                    margin-right: 17vw;
                }
                .pointSeven{
                    margin-bottom: -40vh;
                    margin-right: 42vw;
                }
                .pointEight{
                    margin-bottom: -5vh;
                    margin-right: 28vw;
                }
            }
            .selected{
                background-color: #8E7144;
            }
            .optionselected{
                min-width: 30vw;
                max-width: 30vw;
                margin-left: 15vw;
                min-height: 5vh;
                background-color: rgba(255,255,255,0.5);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1rem;
                letter-spacing: 0.2rem;
                color: #8E7144;
                font-weight: 600;
                font-family: Avenir;
            }
        }
    
    
        .sectionCard{
            z-index: 10;
            position: absolute;
            margin-top: 15vh;
            margin-left: 62.5vw;
            max-width: 35vw;
            max-width: 35vw;
            min-height: 14vh;
            background-color: #8E7144;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2rem;
            text-align: center;
            color: #FFF;
            font-family: Gabi;
            font-style: oblique;
        }
    
    
        
        .sectionForm{
            position: absolute;
            margin-left: 60vw;
            z-index: 6;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 0vh;
            align-items: center;
            min-width: 40vw;
            min-height: 70vh;
            padding-top: 20vh;   
            padding-bottom: 10vh;            
            backdrop-filter: blur(5px);
            background-color: rgba(181,158,143, 0.2);
            form{
                z-index: 8;
                display: flex;
                flex-direction: column;
                margin: 0;
                div{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 5vh;
                    margin: 0;
                    font-family: Avenir;
                    .selectOptions{
                        z-index: 10;
                        min-width: 30vw;
                        max-width: 30vw;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        label{
                            min-width: 30vw;
                            max-width: 30vw;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .seleccionDesplegable{
                            min-width: 30vw;
                            max-width: 30vw;                    
                        }
                            .imagen{
                                z-index: 20;
                                margin-left: 0;
                                min-height: 5vh;
                                margin-left: 25vw;
                                min-width: 5vw;
                                min-width: 5vw;

                            }
                           
                        }   
                        ul{
                            z-index: 20;
                            z-index: 60;
                            background-color: rgba(255,255,255, 0.2);
                            min-width: 30vw;
                            max-width: 30vw;
                            margin: 0;
                            color: #000;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            
                            li{
                                color: #000;
                                z-index: 20;
                                margin: 0;
                                min-width: 15vw;
                                max-width: 15vw;
                                text-align: left;
                                label{
                                    text-align: center;
                                    min-width: 15vw;
                                    max-width: 15vw;
                                }
                            }
                        
                        }                    
                    }
    
                    input{
                        z-index: 8;
                        color: #FFF;
                        min-width: 30vw;
                        border-radius: 0.5rem;
                        min-height: 4vh;
                        background-color: transparent;
                        border: none;
                        border: 1px solid rgba(255,255,255, 0.5);
                        color: #FFF;
                        font-size: 1rem;
                        text-align: center;
                        font-family: Avenir;
                        font-weight: 600;
                        margin: 0;
                    }
                    label{
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;
                        margin-top: 0.5rem;
                        
                    }
                    input::placeholder{
                        color: #FFF;
                    }
                  
                }
    
                .agendacita{
                    z-index: 6;
                    display: flex;
                    min-height: 10vh;
                    justify-content: center;
                    flex-direction: row;
                    align-items: center;
                    font-size: 1.2rem;
                    color: #FFF;
                    label{
                        min-height: 6vh;
                        font-family: Avenir;
                        font-size: 1.3rem;
    
                    }
                    hr{
                        min-width: 15vw;
                    }
                }
            }
    
        }
       
        .giftcard{
            z-index: 8;
            position: absolute;
            min-width: 30vw;
            max-width: 30vw;            
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 10vh;
            margin-top: 85vh;
            margin-left: 65vw;
            button{
                min-width: 30vw;
                min-height: 8vh;
                border-radius: 0.5rem;
                border-color: #FFF;
                background-color: #906D37;
                font-size: 1rem;
                color: #FFF;
                font-family: Avenir;
                font-weight: 600;
            }
        }
    
        .footer{
            z-index: 8;
            position: absolute;
            min-width: 60vw;
            display: flex;
            flex-direction: column;
            min-height: 5vh;
            margin-top: 95vh;
            div{
                display: flex;
                flex-direction: row;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                label{
                    min-width: 20vw;
                    max-width: 30vw;          
                    font-size: .9rem;        
                    font-family: Avenir;
                    font-weight: 600;
                    line-height: 1.5rem;
                    a{
                        color: #FFF;
                    }
                }
                img{
                    z-index: 8;
                    position: absolute;
                    display: flex;
                    margin-top: -180vh;
                    margin-left: 100vw;
                    margin-bottom: 0vh;
                    
                    
                    min-width: 20vw;
                    max-width: 20vw;
                }
            }
        }
        
    }
}

@media (min-width:1440px) and (min-height: 900px)  {
    .generalContainerLanding{
        min-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to bottom right, #B59E8F, #AF8E5B);
    
        display: flex;
        flex-direction: row;
    
        .sectionOne{

            z-index: 5;
            position: absolute;
            min-height: 100vh;
            max-height: 100vh;
            background-image: url('../../img/newfondo.png');
            background-size: cover;
            background-size: 120%;
                
            background-position: center;
            background-position-x: -5vw;
            background-repeat: no-repeat;
            max-width: 70vw;
            min-width: 70vw;
            .header{
                z-index: 7;
                display: flex;
                max-width: 50vw;
                margin-left: 0vw;
                .footersectionOne{
                    min-width: 50vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-top: 2vh;
                    label{
                        
                        min-width: 35vw;
                        max-width: 35vw;
                    }
                    .texone{
                        margin-top: -5vh;
                        font-size: 2.5rem;
                        color: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 12vh;
                        font-family: Gabi;
                        font-weight: lighter;

                    }
                    .textwo{
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    display: none;
                    img{
                        display: none;
                        min-width: 10vw;
                    }
                }
            }
            .pointsSelection{
                min-height: 55vh;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 15vh;
                div{
                    margin-left: 10vw;
                    color: #FFF;
                    border: 1px solid #FFF;
                    border: 1px solid rgba(255,255,255, 0.5);
                    min-width: 2.5rem;
                    max-width: 2.5rem;
                    min-height: 2.5rem;
                    max-height: 2.5rem;
                    border-radius: 1.5rem;
                    font-size: 1.5rem;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    font-family: Avenir;
                    opacity: 0.6;
                }
                .pointOne{
                    margin-bottom: 15vh;
                    margin-right: -10vw;
                }
                .pointTwo{
                    margin-bottom: 15vh;
                    margin-right: 35vw;
                }
                .pointThree{
                    margin-bottom: 15vh;
                    margin-right: 22vw;
                }
                .pointFour{
                    margin-bottom: -30vh;
                    margin-left: 20vw;
                }
                .pointFive{
                    margin-bottom: -25vh;
                    margin-right: 55vw;
                }
                .pointSix{
                    margin-bottom: -25vh;
                    margin-right: 17vw;
                }
                .pointSeven{
                    margin-bottom: -40vh;
                    margin-right: 42vw;
                }
                .pointEight{
                    margin-bottom: -5vh;
                    margin-right: 28vw;
                }
            }
            .selected{
                background-color: #8E7144;
            }
            .optionselected{
                min-width: 30vw;
                max-width: 30vw;
                margin-left: 15vw;
                min-height: 5vh;
                background-color: rgba(255,255,255,0.5);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1rem;
                letter-spacing: 0.2rem;
                color: #8E7144;
                font-weight: 600;
                font-family: Avenir;
            }
        }
    
    
        .sectionCard{
            z-index: 10;
            position: absolute;
            margin-top: 15vh;
            margin-left: 62.5vw;
            max-width: 35vw;
            max-width: 35vw;
            min-height: 14vh;
            background-color: #8E7144;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2rem;
            text-align: center;
            color: #FFF;
            font-family: Gabi;
            font-style: oblique;
        }
    
    
        
        .sectionForm{
            position: absolute;
            margin-left: 60vw;
            z-index: 6;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 0vh;
            align-items: center;
            min-width: 40vw;
            min-height: 70vh;
            padding-top: 20vh;   
            padding-bottom: 10vh;            
            backdrop-filter: blur(5px);
            background-color: rgba(181,158,143, 0.2);
            form{
                z-index: 8;
                display: flex;
                flex-direction: column;
                margin: 0;
                div{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 5vh;
                    margin: 0;
                    font-family: Avenir;
                    .selectOptions{
                        z-index: 10;
                        min-width: 30vw;
                        max-width: 30vw;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        label{
                            min-width: 30vw;
                            max-width: 30vw;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .seleccionDesplegable{
                            min-width: 30vw;
                            max-width: 30vw;                    
                        }
                            .imagen{
                                z-index: 20;
                                margin-left: 0;
                                min-height: 5vh;
                                margin-left: 25vw;
                                min-width: 5vw;
                                min-width: 5vw;

                            }
                           
                        }   
                        ul{
                            z-index: 20;
                            z-index: 60;
                            background-color: rgba(255,255,255, 0.2);
                            min-width: 30vw;
                            max-width: 30vw;
                            margin: 0;
                            color: #000;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            
                            li{
                                color: #000;
                                z-index: 20;
                                margin: 0;
                                min-width: 15vw;
                                max-width: 15vw;
                                text-align: left;
                                label{
                                    text-align: center;
                                    min-width: 15vw;
                                    max-width: 15vw;
                                }
                            }
                        
                        }                    
                    }
    
                    input{
                        z-index: 8;
                        color: #FFF;
                        min-width: 30vw;
                        border-radius: 0.5rem;
                        min-height: 4vh;
                        background-color: transparent;
                        border: none;
                        border: 1px solid rgba(255,255,255, 0.5);
                        color: #FFF;
                        font-size: 1rem;
                        text-align: center;
                        font-family: Avenir;
                        font-weight: 600;
                        margin: 0;
                    }
                    label{
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;
                        margin-top: 0.5rem;
                        
                    }
                    input::placeholder{
                        color: #FFF;
                    }
                  
                }
    
                .agendacita{
                    z-index: 6;
                    display: flex;
                    min-height: 10vh;
                    justify-content: center;
                    flex-direction: row;
                    align-items: center;
                    font-size: 1.2rem;
                    color: #FFF;
                    label{
                        min-height: 6vh;
                        font-family: Avenir;
                        font-size: 1.3rem;
    
                    }
                    hr{
                        min-width: 15vw;
                    }
                }
            }
    
        }
       
        .giftcard{
            z-index: 8;
            position: absolute;
            min-width: 30vw;
            max-width: 30vw;            
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 10vh;
            margin-top: 85vh;
            margin-left: 65vw;
            button{
                min-width: 30vw;
                min-height: 8vh;
                border-radius: 0.5rem;
                border-color: #FFF;
                background-color: #906D37;
                font-size: 1rem;
                color: #FFF;
                font-family: Avenir;
                font-weight: 600;
            }
        }
    
        .footer{
            z-index: 8;
            position: absolute;
            min-width: 60vw;
            display: flex;
            flex-direction: column;
            min-height: 5vh;
            margin-top: 93vh;
            div{
                display: flex;
                flex-direction: row;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                label{
                    min-width: 20vw;
                    max-width: 30vw;          
                    font-size: .9rem;        
                    font-family: Avenir;
                    font-weight: 600;
                    line-height: 1.5rem;
                    a{
                        color: #FFF;
                    }
                }
                img{
                    z-index: 8;
                    position: absolute;
                    display: flex;
                    margin-top: -173vh;
                    margin-left: 100vw;
                    margin-bottom: 0vh;
                    
                    
                    min-width: 20vw;
                    max-width: 20vw;
                }
            }
        }
        
    }
}



@media (min-width:1536px) and (min-height: 864px)  {
    .generalContainerLanding{
        min-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to bottom right, #B59E8F, #AF8E5B);
    
        display: flex;
        flex-direction: row;
    
        .sectionOne{

            z-index: 5;
            position: absolute;
            min-height: 100vh;
            max-height: 100vh;
            background-image: url('../../img/newfondo.png');
            background-size: cover;
            background-size: 120%;
                
            background-position: center;
            background-position-x: -5vw;
            background-repeat: no-repeat;
            max-width: 70vw;
            min-width: 70vw;
            .header{
                z-index: 7;
                display: flex;
                max-width: 50vw;
                margin-left: 0vw;
                .footersectionOne{
                    min-width: 50vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-top: 5vh;
                    label{
                        
                        min-width: 35vw;
                        max-width: 35vw;
                    }
                    .texone{
                        margin-top: -5vh;
                        font-size: 2.5rem;
                        color: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 12vh;
                        font-family: Gabi;
                        font-weight: lighter;

                    }
                    .textwo{
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    display: none;
                    img{
                        display: none;
                        min-width: 10vw;
                    }
                }
            }
            .pointsSelection{
                min-height: 55vh;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 15vh;
                div{
                    margin-left: 10vw;
                    color: #FFF;
                    border: 1px solid #FFF;
                    border: 1px solid rgba(255,255,255, 0.5);
                    min-width: 2.5rem;
                    max-width: 2.5rem;
                    min-height: 2.5rem;
                    max-height: 2.5rem;
                    border-radius: 1.5rem;
                    font-size: 1.5rem;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    font-family: Avenir;
                    opacity: 0.6;
                }
                .pointOne{
                    margin-bottom: 15vh;
                    margin-right: -10vw;
                }
                .pointTwo{
                    margin-bottom: 15vh;
                    margin-right: 35vw;
                }
                .pointThree{
                    margin-bottom: 15vh;
                    margin-right: 22vw;
                }
                .pointFour{
                    margin-bottom: -30vh;
                    margin-left: 20vw;
                }
                .pointFive{
                    margin-bottom: -25vh;
                    margin-right: 55vw;
                }
                .pointSix{
                    margin-bottom: -25vh;
                    margin-right: 17vw;
                }
                .pointSeven{
                    margin-bottom: -40vh;
                    margin-right: 42vw;
                }
                .pointEight{
                    margin-bottom: -5vh;
                    margin-right: 28vw;
                }
            }
            .selected{
                background-color: #8E7144;
            }
            .optionselected{
                min-width: 30vw;
                max-width: 30vw;
                margin-left: 15vw;
                min-height: 5vh;
                background-color: rgba(255,255,255,0.5);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1rem;
                letter-spacing: 0.2rem;
                color: #8E7144;
                font-weight: 600;
                font-family: Avenir;
            }
        }
    
    
        .sectionCard{
            z-index: 10;
            position: absolute;
            margin-top: 15vh;
            margin-left: 62.5vw;
            max-width: 35vw;
            max-width: 35vw;
            min-height: 14vh;
            background-color: #8E7144;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2rem;
            text-align: center;
            color: #FFF;
            font-family: Gabi;
            font-style: oblique;
        }
    
    
        
        .sectionForm{
            position: absolute;
            margin-left: 60vw;
            z-index: 6;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 0vh;
            align-items: center;
            min-width: 40vw;
            min-height: 70vh;
            padding-top: 20vh;   
            padding-bottom: 10vh;            
            backdrop-filter: blur(5px);
            background-color: rgba(181,158,143, 0.2);
            form{
                z-index: 8;
                display: flex;
                flex-direction: column;
                margin: 0;
                div{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 5vh;
                    margin: 0;
                    font-family: Avenir;
                    .selectOptions{
                        z-index: 10;
                        min-width: 30vw;
                        max-width: 30vw;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        label{
                            min-width: 30vw;
                            max-width: 30vw;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .seleccionDesplegable{
                            min-width: 30vw;
                            max-width: 30vw;                    
                        }
                            .imagen{
                                z-index: 20;
                                margin-left: 0;
                                min-height: 5vh;
                                margin-left: 25vw;
                                min-width: 5vw;
                                min-width: 5vw;

                            }
                           
                        }   
                        ul{
                            z-index: 20;
                            z-index: 60;
                            background-color: rgba(255,255,255, 0.2);
                            min-width: 30vw;
                            max-width: 30vw;
                            margin: 0;
                            color: #000;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            
                            li{
                                color: #000;
                                z-index: 20;
                                margin: 0;
                                min-width: 15vw;
                                max-width: 15vw;
                                text-align: left;
                                label{
                                    text-align: center;
                                    min-width: 15vw;
                                    max-width: 15vw;
                                }
                            }
                        
                        }                    
                    }
    
                    input{
                        z-index: 8;
                        color: #FFF;
                        min-width: 30vw;
                        border-radius: 0.5rem;
                        min-height: 4vh;
                        background-color: transparent;
                        border: none;
                        border: 1px solid rgba(255,255,255, 0.5);
                        color: #FFF;
                        font-size: 1rem;
                        text-align: center;
                        font-family: Avenir;
                        font-weight: 600;
                        margin: 0;
                    }
                    label{
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;
                        margin-top: 0.5rem;
                        
                    }
                    input::placeholder{
                        color: #FFF;
                    }
                  
                }
    
                .agendacita{
                    z-index: 6;
                    display: flex;
                    min-height: 10vh;
                    justify-content: center;
                    flex-direction: row;
                    align-items: center;
                    font-size: 1.2rem;
                    color: #FFF;
                    label{
                        min-height: 6vh;
                        font-family: Avenir;
                        font-size: 1.3rem;
    
                    }
                    hr{
                        min-width: 15vw;
                    }
                }
            }
    
        }
       
        .giftcard{
            z-index: 8;
            position: absolute;
            min-width: 30vw;
            max-width: 30vw;            
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 10vh;
            margin-top: 85vh;
            margin-left: 65vw;
            button{
                min-width: 30vw;
                min-height: 8vh;
                border-radius: 0.5rem;
                border-color: #FFF;
                background-color: #906D37;
                font-size: 1rem;
                color: #FFF;
                font-family: Avenir;
                font-weight: 600;
            }
        }
    
        .footer{
            z-index: 8;
            position: absolute;
            min-width: 60vw;
            display: flex;
            flex-direction: column;
            min-height: 5vh;
            margin-top: 93vh;
            div{
                display: flex;
                flex-direction: row;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                label{
                    min-width: 20vw;
                    max-width: 30vw;          
                    font-size: .9rem;        
                    font-family: Avenir;
                    font-weight: 600;
                    line-height: 1.5rem;
                    a{
                        color: #FFF;
                    }
                }
                img{
                    z-index: 8;
                    position: absolute;
                    display: flex;
                    margin-top: -173vh;
                    margin-left: 100vw;
                    margin-bottom: 0vh;
                    
                    
                    min-width: 20vw;
                    max-width: 20vw;
                }
            }
        }
        
    }
}

@media (min-width:1600px) and (min-height: 900px)  {

}

@media (min-width:1920px) and (min-height: 1080px)  {

}









/*


@media (min-width:2560px)  {
    .generalContainerLanding{
        min-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to bottom right, #B59E8F, #AF8E5B);
    
        display: flex;
        flex-direction: row;
    
        .sectionOne{
            z-index: 1;
            position: absolute;
            min-height: 100vh;
            background-image: url('../../img/newfondo.png');
            background-size: cover;
            background-size: 110%;
                
            background-position: center;
            background-position-x: 0vw;
            background-repeat: no-repeat;
            max-width: 70vw;
            min-width: 70vw;
            .header{
                display: flex;
                max-width: 30vw;
                margin-left: 5vw;
                .footersectionOne{
                    min-width: 30vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    label{
                        min-width: 30vw;
                        max-width: 30vw;
                    }
                    .texone{
                        margin-top: 2vh;
                        font-size: 4rem;
                        color: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 12vh;
                        font-family: Gabi;
                        font-weight: lighter;

                    }
                    .textwo{
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        display: none;
                        min-width: 10vw;
                    }
                }
            }
            .pointsSelection{
                min-height: 60vh;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 5vh;
                div{
                    margin-left: 10vw;
                    color: #FFF;
                    border: 1px solid #FFF;
                    min-width: 2.5rem;
                    max-width: 2.5rem;
                    min-height: 2.5rem;
                    max-height: 2.5rem;
                    border-radius: 1.5rem;
                    font-size: 1.5rem;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    font-family: Avenir;
                    opacity: 0.6;
                }
                .pointOne{
                    margin-bottom: 18vh;
                    margin-right: 18vw;
                }
                .pointTwo{
                    margin-bottom: 5vh;
                    margin-right: 30vw;
                }
                .pointThree{
                    margin-bottom: -10vh;
                    margin-right: 10vw;
                }
                .pointFour{
                    margin-bottom: -45vh;
                    margin-left: 25vw;
                }
                .pointFive{
                    margin-bottom: -25vh;
                    margin-right: 50vw;
                }
                .pointSix{
                    margin-bottom: -40vh;
                    margin-right: 15vw;
                }
                .pointSeven{
                    margin-bottom: -55vh;
                    margin-right: 35vw;
                }
            }
            .selected{
                background-color: #8E7144;
            }
            .optionselected{
                min-width: 30vw;
                max-width: 30vw;
                margin-left: 15vw;
                min-height: 5vh;
                background-color: rgba(255,255,255,0.5);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1rem;
                letter-spacing: 0.2rem;
                color: #8E7144;
                font-weight: 600;
                font-family: Avenir;
            }
        }
    
    
        .sectionCard{
            z-index: 6;
            position: absolute;
            margin-top: 12vh;
            margin-left: 70vw;
            max-width: 20vw;
            min-height: 10vh;
            background-color: #8E7144;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2rem;
            text-align: center;
            color: #FFF;
            font-family: Gabi;
            font-style: oblique;
        }
    
    
        
        .sectionForm{
            position: absolute;
            margin-left: 60vw;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 40vw;
            min-height: 100vh;
            backdrop-filter: blur(5px);
            background-color: rgba(164, 136,111, 0.6);
            form{
                z-index: 3;
                display: flex;
                flex-direction: column;
                div{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 10vh;
                    
                    font-family: Avenir;
                    input{
                        color: #FFF;
                        min-width: 20vw;
                        border-radius: 0.5rem;
                        min-height: 4vh;
                        background-color: transparent;
                        border: none;
                        border: 1px solid rgba(255,255,255, 0.5);
                        color: #FFF;
                        font-size: 1.2rem;
                        text-align: center;
                        font-family: Avenir;
                        font-weight: 600;
                    }
                    label{
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;
                        margin-top: 0.5rem;
                    }
                    input::placeholder{
                        color: #FFF;
                    }
                }
    
                .agendacita{
                    display: flex;
                    min-height: 10vh;
                    justify-content: center;
                    flex-direction: row;
                    align-items: center;
                    font-size: 1.2rem;
                    color: #FFF;
                    label{
                        min-height: 6vh;
                        font-family: Avenir;
                        font-size: 1.3rem;
    
                    }
                    hr{
                        min-width: 15vw;
                    }
                }
            }
    
        }
       
        .giftcard{
            z-index: 5;
            position: absolute;
            min-width: 20vw;
            max-width: 20vw;            
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 8vh;
            margin-top: 85vh;
            margin-left: 70vw;
            button{
                min-width: 20vw;
                min-height: 5vh;
                border-radius: 0.5rem;
                border-color: #FFF;
                background-color: #906D37;
                font-size: 1.5rem;
                color: #FFF;
                font-family: Avenir;
                font-weight: 600;
            }
        }
    
        .footer{
            position: absolute;
            min-width: 60vw;
            display: flex;
            flex-direction: column;
            min-height: 5vh;
            margin-top: 95vh;
            div{
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                label{
                    min-width: 50vw;
                    max-width: 50vw;          
                    font-size: .9rem;        
                    font-family: Avenir;
                    font-weight: 600;
                    line-height: 1.5rem;
                    a{
                        color: #FFF;
                    }
                }
                img{
                    z-index: 5;
                    position: absolute;
                    display: flex;
                    margin-top: -185vh;
                    margin-left: 100vw;
                    margin-bottom: 0vh;
                    
                    
                    min-width: 20vw;
                    max-width: 20vw;
                }
            }
        }
        
    }
}*/